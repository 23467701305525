'use client';

import { LucideIcon } from 'lucide-react';
import Link from 'next/link';

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';

interface NavProps {
  links: {
    title: string;
    label?: string;
    icon?: LucideIcon;
    href: string;
  }[];
}

const formatPath = (path: string) => {
  if (!path.endsWith('/')) {
    return `${path}/`;
  }
  if (path.endsWith('index.html')) {
    return path.replace('index.html', '');
  }
  return path;
};

export const DashboardTopNavigation = observer(({ links }: NavProps) => {
  const pathname = formatPath(usePathname());

  return (
    <nav className="flex space-x-4">
      {links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={cn(
            buttonVariants({
              variant: pathname.startsWith(link.href) ? 'secondary' : 'ghost',
              size: 'sm',
            }),
            pathname.startsWith(link.href)
              ? 'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white'
              : '',
            'justify-start',
            'rounded-full',
          )}
        >
          {link.icon && <link.icon className="mr-2 h-4 w-4" />}
          {link.title}
          {link.label && (
            <span
              className={cn(
                'ml-auto',
                pathname === link.href ? 'text-background dark:text-white' : '',
              )}
            >
              {link.label}
            </span>
          )}
        </Link>
      ))}
    </nav>
  );
});
