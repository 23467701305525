'use client';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { useRootStore } from '../_providers/root-store-provider';

interface InstallationSwitcherProps {
  className?: string;
}

export const InstallationSwitcher = observer(
  ({ className }: InstallationSwitcherProps) => {
    const rootStore = useRootStore();
    return rootStore.sessionStore.installations ? (
      <Select
        defaultValue={rootStore.sessionStore.currentInstallation?.rawData.name}
        value={rootStore.sessionStore.currentInstallation?.rawData.name}
        onValueChange={(value) => {
          rootStore.sessionStore.currentInstallation =
            rootStore.sessionStore.installations?.items.find(
              (installation) => installation.rawData.name === value,
            ) || undefined;
        }}
      >
        <SelectTrigger
          className={cn(
            'flex max-w-fit items-center gap-2 overflow-hidden [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
            className,
          )}
          aria-label="Select installation"
        >
          <SelectValue
            className="bg-popover"
            placeholder="Select an installation"
          >
            {rootStore.sessionStore.currentInstallation?.rawData.avatarUrl ? (
              <Image
                className="h-6 w-6 rounded-full"
                src={
                  rootStore.sessionStore.currentInstallation?.rawData.avatarUrl
                }
                width={24}
                height={24}
                alt="avatar"
              />
            ) : (
              <></>
            )}
            <span className={cn('ml-2')}>
              {
                rootStore.sessionStore.installations?.items.find(
                  (installation) =>
                    installation.rawData.name ===
                    rootStore.sessionStore.currentInstallation?.rawData.name,
                )?.rawData.name
              }
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>App Installations</SelectLabel>
            {rootStore.sessionStore.installations.items.map((installation) => (
              <SelectItem
                key={installation.rawData.name}
                value={installation.rawData.name}
              >
                <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                  {installation.rawData.avatarUrl ? (
                    <Image
                      className="h-6 w-6 rounded-full"
                      src={installation.rawData.avatarUrl || ''}
                      alt="avatar"
                      width={24}
                      height={24}
                    />
                  ) : (
                    <></>
                  )}
                  {installation.rawData.name}
                </div>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    ) : (
      <Select disabled>
        <div className="flex justify-center">
          <SelectTrigger
            className={cn(
              'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
            )}
            aria-label="Loading"
          >
            <SelectValue placeholder="Loading Installations...">
              <Loader2
                className={cn('my-28 h-16 w-16 animate-spin text-primary/60')}
              />
            </SelectValue>
          </SelectTrigger>
        </div>
      </Select>
    );
  },
);
